import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import Dropzone from 'react-dropzone-uploader';


import {
  getRest as onGetRest,
  addRest as onAddRest,
  updateRest as onUpdateRest,
  deleteRest as onDeleteRest,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "RESTORANT | BİTTİ GİTTİ ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState(null);
  const [getFileNameSecond, setFileNameSecond] = useState('');


  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getUserID, setUserID] = useState();


  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  const [getIsService, setIsService] = useState(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.ResName) || "",
      link: (contactEdit && contactEdit.ResTime) || "",
      commission: (contactEdit && contactEdit.Rest_Commission) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Restorant İsmini Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {
        

        const updateUser = {
          ID: contactEdit.ID,
          ResName: values.title,
          RestImage: getFileName,
          Rest_Active: getIsActive,
          Rest_Category_ID: getCategoryID,
          Users_ID : getUserID,
          Rest_Commission:  parseInt(values.commission)
        };
        dispatch(onUpdateRest(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          ResName: values["title"],
          RestImage: getFileName,
          Rest_Active: getIsActive,
          Rest_Category_ID: getCategoryID,
          Users_ID :  getUserID,
          Rest_Commission:  parseInt(values["commission"])
        };
        // save new user
        dispatch(onAddRest(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.rest,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getUserType, setUserType] = useState();


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.RestImage ? (
              <div className="avatar-xs">
                {cellProps.ResName != null ? <span className="avatar-title rounded-circle">
                  {cellProps.ResName.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.RestImage}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Restoran Adı",
        accessor: "ResName",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     
      {
        Header: "Kategori",
        accessor: "Rest_Category.Rest_Category_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

    
      {
        Header: "Komisyon",
        accessor: "Rest_Commission",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kullanıcı",
        accessor: "Users.Users_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      
      {
        Header: "Aktiflik Durumu",
        accessor: "Rest_Active",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://bittigitti.stechomeyazilim.info:5151/getRest_CategoryBitti/select/`)
      .then((res) => {
        setCategory(res.data)
      })


  }


  const _getAuthData1 = async () => {

    await axios.get(`https://bittigitti.stechomeyazilim.info:5151/getUsersBitti/select/`)
      .then((res) => {
        setUserList(res.data)
      })


  }



  useEffect(() => {
    _getAuthData()
    _getAuthData1()

    let getData = localStorage.getItem("authUser");

    if (users && !users.length) {
      if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
        setUserType(JSON.parse(localStorage.getItem("authUser")).UserTypeID)
        dispatch(onGetRest(JSON.parse(getData).ID));
        setIsEdit(false);
      }else{
        setUserType(null)
        dispatch(onGetRest(null));
        setIsEdit(false);
      }
     
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
  
    setCategoryID(user.Rest_Category_ID)


    setIsActive(user.Rest_Active)
  
    setUserID(user.Users_ID)

    setFileName(user.RestImage)
    setIsEdit(true);


    toggle();
  };




  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteRest(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {

    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceUser = arg => {
    setUserID(arg.target.value)
  }

  
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _addMedia = () => {


    getFileSubMediaAdd.map((item, index) => {
      const newUser = {
        Education_SubItem_ID: getSubMediaID,
        EducationSubItem_Media_Type: item.fileType,
        EducationSubItem_Media_Link: item.fileName
      }

      axios.post('https://bittigitti.stechomeyazilim.info:5151/addSubEducationMediaDiz/send', newUser)

      dispatch(onGetRest());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  const handleChangeStatusSecond = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameSecond(result.file.filename)

        }
      }
    }
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Restorant Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddRest={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatusBanner}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                        {getFileSubMedia.map((item, index) => (
                          item.EducationSubItem_Media_Type == "video/mp4" ?
                            <div key={index}>
                              <video width="150" height="150" controls>
                                <source src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} type="video/mp4" />
                              </video>

                            </div> :

                            <div key={index}>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} alt="" className="avatar-sm" />
                            </div>
                        ))}



                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addMedia()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Medyaları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Restorant Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Restorant Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                                                                                      <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Rest_Category_Name}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Restorant Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Restorant Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>



                            {JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? null :
                            <div className="mb-3">
                              <Label className="form-label">% Komisyon Giriniz</Label>
                              <Input
                                name="commission"
                                label="commission"
                                type="number"
                                placeholder="Komisyon Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.commission || ""}
                                invalid={
                                  validation.touched.commission &&
                                    validation.errors.commission
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.commission &&
                                validation.errors.commission ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.commission}
                                </FormFeedback>
                              ) : null}
                            </div> }
                            

                            {JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? null :
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div> }

                          
{JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? null :
<div className="mb-3">
  <Label className="form-label">Kullanıcı Seçiniz</Label>
  <Input
    name="paymentStatus"
    type="select"
    className="form-select"
    defaultValue={getUserID}
    onChange={(text) => _setChoiceUser(text)}
    value={
      getUserID
    }>
      <option value="">Lütfen Seçim Yapınız</option>
    {userList.map((item, index) => (
      <option value={item.ID} key={item.ID}>{item.Users_Name}</option>
    ))}

  </Input>
  {validation.touched.wsText && validation.errors.wsText ? (
    <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
  ) : null}
</div>
}
                        
{/*
                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Restorant Logo</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>


                            <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                /> */}

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
