import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Price } from "./contactlistCol";
import DateModal from "components/Common/DateModal";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import CompanyModal from "components/Common/CompanyModal";

import axios from 'axios';

import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import {ExportToExcel2} from '../../../ExportToExcel2'
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import {
  getSiparis as onGetSiparis,
  addSiparis as onAddSiparis,
  updateSiparis as onUpdateSiparis,
  deleteSiparis as onDeleteSiparis,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Siparişler | BİTTİ GİTTİ ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getDetail, setDetail] = useState([]);

  const validation = useFormik({
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Order_Status_ID: getCategoryID,
        };
        dispatch(onUpdateSiparis(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Order_Status_ID: getCategoryID,
        };
        dispatch(onAddSiparis(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.siparis,
  }));

  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [gapetRest, setRest] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);

  const [getCategory, setCategory] = useState([]);

  
  const groupUsersByComplatedOrderID = (users) => {
    const groupedUsers = {};
    users.forEach(user => {
      const complatedOrderID = user.Complated_Order_ID;
      if (!groupedUsers[complatedOrderID]) {
        groupedUsers[complatedOrderID] = [];
      }
      groupedUsers[complatedOrderID].push(user);
    });
    return groupedUsers;
  };
  
  // Gruplanmış kullanıcıları al
  const groupedUsers = groupUsersByComplatedOrderID(users);
  
  /** 
  const tableData = Object.values(groupedUsers).map(group => {
    // Grup içindeki ilk kullanıcının özelliklerini kullanarak bir temsilci oluştur
    const representativeUser = group[0];
    return {
      ID: representativeUser.ID, // Temsilci kullanıcının ID'si
      // Diğer istediğiniz özellikler buraya eklenebilir
      // Örneğin: Complated_Order_ID gibi
    };
  });*/
  
  
  const tableData = Object.values(groupedUsers).map(group => {
    const combinedItem = group.reduce((combined, current) => {
      // Toplam miktarı birleştir
      combined.Quantity += current.Quantity;
      combined.data = group[0];
      combined.orderDetail = group;

      combined.Products.Price += current.Rest_Product.Products.Price * current.Quantity;
  
      return combined;
    }, {
      orderDetail : [],
      data: null, // Temsilci kullanıcının ID'si
      Quantity: 0,
        Products: {
          Price: 0
        }
    });
  
    return combinedItem;
  });
  
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "data.Complated_Order_ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Toplam Ürün",
        accessor: "Quantity",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Toplam Fiyat",
        accessor: "Products.Price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Ödeme Tipi",
        accessor: "data.Complated_Order.Complated_Order_Type",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Sipariş Tarihi",
        accessor: "data.Complated_Order.Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
    
      {
        Header: "Kullanıcı",
        accessor: "data.Complated_Order.Users.Users_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Sipariş Durumu",
        accessor: "data.Complated_Order.Order_Status.Order_Status_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     

      {
        Header: "Rest. Adı",
        accessor: "data.Rest_Product.Rest.ResName",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     
    
  
      {
        Header: "Sipariş Notu",
        accessor: "data.Complated_Order.Complated_Order_Note",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickDetail(userData.orderDetail);
                }}
              >
                <i style={{ color: cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş İptal Edildi" ? 'blue' : cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş Teslim Edildi" ? 'blue' : null}} className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Detay
                </UncontrolledTooltip>
              </Link>

             { cellProps.row.original.data.Complated_Order.Order_Status_ID == 1 ?
              <div>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickConfirm(userData.data.Complated_Order);
                }}
              >
                <i style={{ color: cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş İptal Edildi" ? 'blue' : cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş Teslim Edildi" ? 'blue' : null}} className="mdi mdi-checkbox-marked-circle-outline font-size-18" id="edittooltipconfirm" />
                <UncontrolledTooltip placement="top" target="edittooltipconfirm">
                  Siparişi Onayla
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickCancel(userData.data.Complated_Order.ID);
                }}
              >
                <i style={{ color: cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş İptal Edildi" ? 'blue' : cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş Teslim Edildi" ? 'blue' : null}} className="mdi mdi-cancel font-size-18" id="edittooltipcancel" />
                <UncontrolledTooltip placement="top" target="edittooltipcancel">
                  Siparişi İptal Et
                </UncontrolledTooltip>
              </Link>
              </div> : null}
           

              { cellProps.row.original.data.Complated_Order.Order_Status_ID == 7 ?
              <div>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickComplate(userData);
                }}
              >
                <i style={{ color: cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş İptal Edildi" ? 'blue' : cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş Teslim Edildi" ? 'blue' : null}} className="mdi mdi-checkbox-marked-circle-outline font-size-18" id="edittooltipconfirm2" />
                <UncontrolledTooltip placement="top" target="edittooltipconfirm2">
                  Siparişi Teslim Ettim
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickCancel(userData.data.Complated_Order.ID);
                }}
              >
                <i style={{ color: cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş İptal Edildi" ? 'blue' : cellProps.row.original.data.Complated_Order.Order_Status.Order_Status_Title == "Sipariş Teslim Edildi" ? 'blue' : null}} className="mdi mdi-cancel font-size-18" id="edittooltipcancel2" />
                <UncontrolledTooltip placement="top" target="edittooltipcancel2">
                  Siparişi İptal Et
                </UncontrolledTooltip>
              </Link>
              </div> : null}
             

            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://bittigitti.stechomeyazilim.info:5151/getOrder_StatusBitti/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()

    let getData = localStorage.getItem("authUser");

    console.log("sdmfkşl",JSON.parse(localStorage.getItem("authUser")).UserTypeID)
    if (users && !users.length) {

      if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
      dispatch(onGetSiparis(JSON.parse(getData).Rest[0].ID))
      setIsEdit(false);
      setRest(true)
      }else{
        dispatch(onGetSiparis(null));
        setIsEdit(false);
        setRest(false)

      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClickDetail = (arg) => {
    const user = arg;
    setDetail(user)
    toggleMedia();
  };

  const handleUserClickComplate = (arg) => {

    console.log("lknscdlkf",arg.data)
   
    if(arg.data.Rest_Product.Products.Stock == 0){
      showToast(false,"Stokta Ürün Bulunmamaktadır!")  
    }else{


    const updateUser = {
          ID: arg.data.Complated_Order.ID,
          Order_Status_ID: 3,
        }
        dispatch(onUpdateSiparis(updateUser));
        
        if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
          setTimeout(() =>  dispatch(onGetSiparis(arg.data.Rest_Product.Rest_ID)), 500)
          }else{
            dispatch(onGetSiparis(null))
          }

          const notification = {
            USERID : arg.data.Users_ID,
            body : "BittiGitti Sipariş",
            title : "Rest. Tarafından Siparişiniz Başarıyla Teslim edilmiştir!",
            icerikTuru : "kullanici"
          }

          console.log("msnd2lf",arg.data.Rest_Product.Products)
          console.log("msnd1lf",arg.data.Rest_Product.Products_ID)

          const updateStock = {
            Stock : arg.data.Rest_Product.Products.Stock - 1
          }

           axios.patch(`https://bittigitti.stechomeyazilim.info:5151/updateStockBitti/${arg.data.Rest_Product.Products_ID}`, updateStock)
          .then(async (res) => {
          
            console.log("res123",res)
          })

          axios.post('https://bittigitti.stechomeyazilim.info:5151/pushNotificationBitti/send', notification)
              
          if(arg.data.Complated_Order.Complated_Order_Type == "Rest. Ödeme"){

    
          if (arg.orderDetail.length > 0) {
            arg.orderDetail.forEach(async (item, index) => {
                const payment = {  
                    Rest_ID: item.Rest_Product.Rest_ID,
                    Users_ID: JSON.parse(localStorage.getItem("authUser")).ID,
                    Created_DateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                    TotalPrice: item.TotalPrice,
                    Rest_Payment_Note: `🧑‍⚕️ ${item.Complated_Order.ID} ID'li Sipariş Teslimi`,
                    Rest_Payment_Type: 'borç'
                };
        
                try {
                    await axios.post('https://bittigitti.stechomeyazilim.info:5151/restPaymenyBitti/send', payment);
                    console.log("İşlem tamamlandı", item);
                } catch (error) {
                    console.error("Hata oluştu", error);
                }
            });
        }
        
      }

              

          showToast(true,"Başarıyla Siparişi Teslim Ettiniz!")  
        }
  };

  const handleUserClickConfirm = (arg) => {

    let getData = localStorage.getItem("authUser")
    
    const updateUser = {
          ID: arg.ID,
          Order_Status_ID: 7,
        }
        dispatch(onUpdateSiparis(updateUser));
        
        if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
          setTimeout(() =>  dispatch(onGetSiparis(JSON.parse(getData).Rest[0].ID)), 500)
          }else{
            dispatch(onGetSiparis(null))
          }

          const notification = {
            USERID : arg.Users_ID,
            body : "BittiGitti Sipariş",
            title : "Rest. Tarafından Siparişiniz Onayladı!",
            icerikTuru : "kullanici"
          }

          axios.post('https://bittigitti.stechomeyazilim.info:5151/pushNotificationBitti/send', notification)
       
          showToast(true,"Başarıyla Siparişi Onayladınız!")

  };

  const handleUserClickCancel = (arg) => {
   
    let getData = localStorage.getItem("authUser")
    
    const updateUser = {
          ID: arg,
          Order_Status_ID: 6,
        }
        dispatch(onUpdateSiparis(updateUser));
        
        if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
          setTimeout(() =>  dispatch(onGetSiparis(JSON.parse(getData).Rest[0].ID)), 500)
          }else{
            dispatch(onGetSiparis(null))
          }
          
          const notification = {
            USERID : arg.Users_ID,
            body : "BittiGitti Sipariş",
            title : "Rest. Tarafından Siparişiniz İptal Edildi!",
            icerikTuru : "kullanici"
          }

          axios.post('https://bittigitti.stechomeyazilim.info:5151/pushNotificationBitti/send', notification)
       

          showToast(false,"Başarıyla Siparişi İptal Ettiniz!")
  };



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [getFilterCheckCompany, setFilterCheckCompany] = useState(false);


  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSiparis(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {

    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const [getExcelModal, setExcelModal] = useState(false);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);

    
  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = getSelectDate

    const dataFilter = users.filter(x => moment(x.Created_DateTime).isSameOrAfter(moment(getSelectDate)) && moment(x.Created_DateTime).isSameOrBefore(moment(getSelectDateFinish)));
           setExcelModal(false)

    const ws = XLSX.utils.json_to_sheet(dataFilter);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }


  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const [getDateClick, setDateClick] = useState(null);

    const [getFilterData, setFilterData] = useState([]);
    const [getFilterCheck, setFilterCheck] = useState(false);
    const [getFilterDataCompany, setFilterDataCompany] = useState([]);
    const [getFilterCheckData, setFilterCheckData] = useState(false);

    const onClickExcel = (order) => {
      setDateClick(order);
      setExcelModal(true);
    }


    
    const _onFilterClickCompany = (val) => {
      console.log("klsdlkf",val)
      console.log("users12321",users)

      setFilterCheckCompany(false)

     
  const dataFilter = users.filter(x => x.Rest_Product.Rest_ID == val);
       
  console.log("dataFilter12321",dataFilter)
        setFilterDataCompany(dataFilter)
        setFilterCheckData(true) 
     
    }

  
    const showToast= async (type,message) => {
      let ele = null
     
    
       if(type == true){
         ele = "success"
         message = message
      } else{
         ele = "error"
         message = message
      };
      const position = ["toast-top-right"];
      let toastType;
      const title = "Başlık";
    
    
      //Close Button
      const closeButton = true;
    
      //Debug
      const debug = false;
    
      //Progressbar
      const progressBar = true;
    
      //Duplicates
      const preventDuplicates = false;
    
      //Newest on Top
      const newestOnTop = true;
    
      //position class
      let positionClass = "toast-top-right";
    
      //Show Easing
      const showEasing = "swing";
    
      //Hide Easing
      const hideEasing = "linear";
    
      //show method
      const showMethod = "fadeIn";
    
      //Hide method
      const hideMethod = "fadeOut";
    
      //show duration
      const showDuration = 300;
    
      //Hide duration
      const hideDuration = 1000;
    
      //timeout
      const timeOut = 5000;
    
      //extended timeout
      const extendedTimeOut = 1000;
    
      //Fetch checked Type
     
       toastType = ele;
      
    
      toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: closeButton,
        debug: debug,
        progressBar: progressBar,
        preventDuplicates: preventDuplicates,
        newestOnTop: newestOnTop,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    
      // setTimeout(() => toastr.success(`Settings updated `), 300)
      //Toaster Types
      if (toastType === "info") toastr.info(message, title);
      else if (toastType === "warning") toastr.warning(message, title);
      else if (toastType === "error") toastr.error(message, title);
      else toastr.success(message, title);
    }
  
      
  return (
    <React.Fragment>

<CompanyModal

        onFilterClick= {_onFilterClickCompany}
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}

        show={getFilterCheckCompany}
        onCloseClick={() => setFilterCheckCompany(false)}
      />


<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}

        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Sipariş Listesi" />

          <ExportToExcel2 title={"Tarih Excel"} onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={users} fileName={"fileName"} />

          {gapetRest == true ? null : getFilterCheckData == true ?  
          <Button
                type="button"
                color="danger"
                className="btn-rounded mb-2 me-2"
                onClick={()=> setFilterCheckData(false)}
              >
                <i className="mdi mdi-delete me-1" />
                Filtrelemeyi Sıfırla
              </Button> : 
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckCompany(true)}

              >
                <i className="mdi mdi-filter me-1" />
               Firmaya Göre Listele
              </Button>}

          {getFilterCheck == true ?  
          <Button
                type="button"
                color="danger"
                className="btn-rounded mb-2 me-2"
                onClick={()=> setFilterCheck(false)}
              >
                <i className="mdi mdi-delete me-1" />
                Listeyi Sıfırla
              </Button> : 
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setExcelModal(true)}

              >
                <i className="mdi mdi-filter me-1" />
                Tarih Aralığı Listele
              </Button>}


             

              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setExcelModal(true)}

              >
                <i className="mdi mdi-checkbox-marked-circle-outline me-1" />
                Aktif Siparişler ({tableData.filter((x)=> x.data.Complated_Order.Order_Status_ID == 1 || x.data.Complated_Order.Order_Status_ID == 7).length})
              </Button>

              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setExcelModal(true)}

              >
                <i className="mdi mdi-cart-outline me-1" />
                Tamamlanan Siparişler ({tableData.filter((x)=> x.data.Complated_Order.Order_Status_ID != 1 && x.data.Complated_Order.Order_Status_ID != 7).length})
              </Button>
              
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={getFilterCheckData == true ? getFilterDataCompany : getFilterCheck == true ? getFilterData : tableData}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProduct={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Sipariş Detayları"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <div className="mb-3">

                            {getDetail.length > 0 ? getDetail.map((item, index) => (
                                  <div key={index} className="mb-3 border p-3">
                                    <div className="d-flex text-center align-self-center justify-content-center">
                                      {item.Rest_Product.Products.MobileCheck == true ? 
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://kampanya.stechomeyazilim.com/Uploads/' + item.Rest_Product.Products.ImageCover}
                  alt=""
                /> : 
                                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + item.Rest_Product.Products.ImageCover}
                  alt=""
                />}
                   <p><strong>Ürün:</strong> {item.Rest_Product.Products.Products_Name}</p>

              </div>

                                  
                                  <p><strong>Ürün Fiyat:</strong> {item.Rest_Product.Products.Price}</p>
                                  <p><strong>Adet:</strong> {item.Quantity}</p>
                                  <p><strong>Toplam Ücret:</strong> {item.TotalPrice} TL</p>
                                  <p><strong>Rest. Adı:</strong> {item.Rest_Product.Rest.ResName}</p>

                                 {/* Add additional properties as needed */}
                                </div>
                                  )) : null }
                                
                              
                            </div>

                          </div>


                        </Col>
                      </Row>


                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>

                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>


                            <div className="mb-3">
                              <Label className="form-label">Sipariş Durumu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                                                    <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Order_Status_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
